.ChatDivContainer{
    .inative{
        display: none;
    }
}

.BubbleText{
    position: fixed;
    bottom: 60px;
    right: 100px;
    z-index: 1;
    display: flex;
    width: 150px;
    height: 50px;
    background-color: rgb(37, 241, 40);
    border-radius: 30px 30px 0px 30px;
    text-align: center;
    justify-content: center;
    padding-top: 15px;
    cursor: pointer;

    span{
        color: #fff;
        font-weight: 600;
    }
}

#blip-chat-open-iframe {
    animation: pulsante 1.5s infinite;

    @keyframes pulsante {
        0% {
            box-shadow: #00ff00 0 0 0 0;
        }
        50% {
            box-shadow: #00ff00fa 0 0 0 16px;
        }
        100% {
            box-shadow: #00ff0000 0 0 0 32px;
        }
    }
}